require('../scss/main.scss')
require('../../node_modules/summernote/dist/summernote-bs4.css')

require('underscore')
require('backbone')
var $ = require('jquery')
// JS is equivalent to the normal "bootstrap" package
// no need to set this to a variable, just require it
require('tether')
require('bootstrap')
//require('holderjs');
require('../../node_modules/summernote/dist/summernote-bs4.js')

$('input[type="file"]').change(function() {
    var files = $(this)[0].files

    var fileName = files.length > 1 ? files.length + ' Dateien' : files[0].name

    var _size = 0

    $.each(files, function(idx, item) {
        _size += item.size
    })

    var fSExt = new Array('Bytes', 'KB', 'MB', 'GB'),
        i = 0
    while (_size > 900) {
        _size /= 1024
        i++
    }
    var exactSize = Math.round(_size * 100) / 100 + ' ' + fSExt[i]

    $('.custom-file-label').html(fileName + ' (' + exactSize + ')')
})

$('a.security-question').click(function() {
    return window.confirm('Wirklich löschen?')
})

$('input.submit').click(function() {
    $(this)
        .closest('form')
        .submit()
})

$('.summernote').each(function() {
    $(this).summernote({
        tabsize: 2,
        height: 100
    })
})
